





function Buyagreements({show})
{

    var mail = "support@tlfiske.se";

    return(
        <div>
       
        <div class="modal fade" id="buymodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Köp- & leveransvillkor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p className="fs-2">Vid köp hos TLfiske</p>
        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Beställning</p>
        <p>För att få handla på TL Fiskes Webbshop behöver du vara 18 år eller ha målsmans tillåtelse. Genom att du skickar iväg din order så har du godkänt köpet och våra köpvillkor. Moms ingår i alla priser (förutom vid export till länder utanför EU), men fraktkostnad tillkommer i förekommande fall: köp under 500 kr, hemleveranser samt utlandspaket). Fraktkostnader för paket som skall fraktas utanför Sverige kommer diskuteras och godkännas via e-post.</p>
        <p> När du slutfört din beställning skickas en orderbekräftelse till din e-postadress. I bekräftelsen finner du alla uppgifter om produkter, pris, fakturerings- och leveransadress.</p>
        <p>Är något fel i orderbekräftelsen ber vi digkontakta oss via e-post till {mail}.</p>

        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Levernas</p>
        <p>Leveranstiden är 2-5 dagar och ditt paket skickas genom postnord.</p>
        <p>Beställningar som läggs under helger skickas tidigast måndagen efter.</p>
        <p>Vid eventuella förseningar, tveka inte att kontakta oss på {mail}.</p>


        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Ångerrätt</p>
        <p>
            Vid köp av varor på webbplatsen har du som kund en lagstiftad 14 dagars ångerrätt som gäller från det att du har tagit emot en vara som du har beställt.
        </p>

        <p className="fw-bold mb-1">Vid nyttjande av din ångerrätt:</p>
        <ul>
            <li>
                Du måste meddela att du ångrar dig. Meddelandet ska skickas till oss {mail}. I ditt meddelande ska ditt namn, din adress, e-postadress, ordernumret samt vilka varor som returneringen gäller framgå klart och tydligt.
            </li>
            <li>
                Du bör omedelbart och senast inom lagstiftad 14 dagar efter ångermeddelandet returnera produkterna till oss.
            </li>
            <li>
                Du står för returfrakt, leverans och skick på produkterna vid retur, produkterna bör därför skickas välpaketerade och i ursprunglig förpackning.
            </li>
            <li>
            På återbetalningsbeloppet förbehåller vi oss rätten att dra av en summa motsvarande värdeminskningen jämfört med varans ursprungliga värde vid använd eller skadad produkt.
            </li>
        </ul>

        <p className="fw-bold mb-1">Ångerrätten gäller inte vid:</p>
        <ul>
            <li>
            Produkter som på grund av hälso- eller hygienskäl har förseglats (plomberats) och där förseglingen (plomberingen) har brutits av dig.
            </li>

            <li>
            Produkter som har karaktär av förseglad ljud- eller bildupptagning och där förseglingen har brutits av dig.
            </li>

            <li>
            Specialtillverkad produkt, som har skräddarsytts särskilt åt dig eller har en tydlig personlig prägel efter dina önskemål.
            </li>

            <li>
            Tjänster som har fullgjorts och där du uttryckligen har samtyckt till att tjänsten påbörjas utan ångerrätt.
            </li>

            <li>
            Varor som snabbt kan försämras, exempelvis livsmedel.
            </li>

            <li>
            Lösnummer av tidningar eller tidskrifter.
            </li>



        </ul>



        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Betalningar</p>
        <p>
        Vi använder Stripe för att hantera betalningar på ett säkert och effektivt sätt. Stripe är en ledande betalningslösning som gör det möjligt för oss att erbjuda flera betalningsmetoder, inklusive kredit- och betalkort.
        </p>

        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Reklamation och klagomål</p>
        <p>
        Vid reklamationer ska du alltid börja med att skicka ett mail till {mail}. Beskriv i mailet varför du vill reklamera och bifoga bilder på skadan, skriv även ditt ordernummer. Beroende på vilken typ av vara och skada det gäller så går det att lösa på olika sätt. Vid de en del fall är det våra leverantörer som avgör vad som går under reklamation därför måste vi få hit den skadade varan för att sedan skicka vidare till leverantören. Hanteringstiden för reklamationer ligger mellan 1-3 veckor men vi försöker självklart lösa det så fort som möjligt. Vi skickar en retursedel till dig så du kan skicka tillbaks den skadade varan. 

        </p>


        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Produktinformation</p>
        <p>
        Vi reserverar oss för eventuella tryckfel på denna webbplats samt slutförsäljning av produkter. Vi garanterar inte att bilderna återger produkternas exakta utseende då en viss färgskillnad kan förekomma beroende på bildskärm, fotokvalitet samt upplösning. Vi försöker alltid på bästa sätt att exponera produkterna så korrekt som möjligt.
        </p>

        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Cookies och lokal lagring</p>
        <p>
        Vi använder endast nödvändiga cookies på vår webbplats. Dessa cookies är avsedda för att säkerställa att vår webbplats fungerar korrekt och för att tillhandahålla de grundläggande funktioner som du förväntar dig när du besöker oss online.
        </p>

        <p>
        Nödvändiga cookies är små textfiler som lagras på din enhet när du besöker vår webbplats. Deras främsta syfte är att säkerställa att viktiga funktioner på webbplatsen fungerar korrekt. Till exempel kan nödvändiga cookies användas för att komma ihåg dina inloggningsuppgifter eller för att spara dina inställningar för kakinställningar.
        </p>


        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Ansvarsbegränsning (Force Majeure)</p>
        <p>
            TL Fiske är befriat från påföljd för underlåtenhet att fullgöra viss förpliktelse enligt detta avtal, om underlåtenheten har sin grund i befriande omständighet enligt nedan och omständigheten förhindrar, försvårar eller försenar fullgörandet. Såsom befriande omständighet skall anses bl. a. myndighets åtgärd eller underlåtenhet, nytillkommen eller ändrad lagstiftning, konflikt på arbetsmarknaden, blockad, brand, översvämning, sabotage, olyckshändelse av större omfattning eller annan typ av naturkatastrof. I force majeure ingår myndighets beslut som påverkar marknad och produkter negativt, t.ex. restriktioner i indikation, varningstexter, försäljningsförbud etc, onormal nedgång i marknaden samt om bolaget utsetts för kriminell aktivitet som påverkar verksamheten. 
            <br/>
            Vi tar inget ansvar för indirekta skador som kan uppstå på grund av produkten.
            <br/>
            Vidare tas inget ansvar för eventuella förändringar på produkter/produktegenskaper som ändrats av respektive leverantör och andra faktorer utanför vår kontroll.
        </p>


        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Ändringar till de Allmänna Villkoren</p>
        <p>
        Vi förbehåller oss rätten att när som helst företa ändringar i villkoren. Ändringar av villkoren kommer att publiceras online på webbplatsen. De ändrade villkoren anses för accepterade i samband med order eller besök på webbplatsen.
        </p>

        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Tvist</p>
        <p>
        Vid eventuell tvist har vi som policy att alltid följa Allmänna Reklamationsnämndens rekommendationer. Du hittar dem på <a href="https://www.arn.se">https://www.arn.se</a>
 
        </p>

        <p className="pb-0 mb-0 fs-4" style={{borderBottom: "1px solid black", display: "inline-block"}}>Kontaktuppgifter e-handel:</p>
        <p>Returer och reklamationer skickas även hit!</p>
        <p className="fw-bold pb-0 mb-0">TLfiske</p>
        <p className="fw-bold pb-0 mb-0">Blåkullagatan 7</p>
        <p className="fw-bold pb-0 mb-0">572 74 Sweden 7</p>
        <p className="fw-bold pb-0 mb-0">E-post: {mail}</p>





      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Stäng</button>
        
      </div>
    </div>
  </div>
</div>
</div>

);




}


export default Buyagreements;