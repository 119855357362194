    import { useEffect, useState } from "react";
    import Cartitem from "./cartproduct";
    import {getCookie, eventEmitter} from "../cookie/cartCookie";
    import { useNavigate } from "react-router-dom";
    import "./cart.css"



    function Cartcanvas()
    {

    const [cartItems, setCartItems] = useState([]);
    const [cartTotalSum, setTotalSum] = useState("0kr");
    const [updateCookie, setUpdateCookie] = useState(false);
    const [animatePrice, setAnimatePrice] = useState(false);
    const [activeButtonToCart, setActiveButtonToCart] = useState(false);

    const navigate = useNavigate();

    const handleNav = (to) => 
    {

        navigate(to);
    };




    useEffect(() => {
        // Define the function inside useEffect to ensure it has access to the current state
        const updateItemCounter = () => {
            setUpdateCookie(true);
        };

        // Add the event listener
        eventEmitter.on('updatedCookie', updateItemCounter);

        // Return a cleanup function that removes the event listener
        return () => {
            eventEmitter.off('updatedCookie', updateItemCounter);
        };
    }, []); // Empty dependency array to run only on mount and unmount


    useEffect( ()=>
    {

        

        const handleCookieUpdate = () => 
        {
            console.log("Handles cookieee!");
            let cookie = getCookie("tlCart");
            
            if(cookie)
            {
                var cartData = JSON.parse(cookie);
                const items = Object.keys(cartData).map((productId) => ({
                    ...cartData[productId],
                    id: productId
                }));
                console.log(items);
                setCartItems(items);
                
                if(items.length === 0) // No items in cart.
                {
                    setActiveButtonToCart(false);
                    setTotalSum("0kr");
                    setAnimatePrice(true);
                    return;
                }



                setActiveButtonToCart(true);
                var tempSum = 0;
                for(let i= 0; i < items.length; i++)
                {
                    
                    console.log(parseInt(items[i].price));
                    tempSum += parseInt(items[i].price) * parseInt(items[i].quantity);
                }
                setAnimatePrice(true);
                setTotalSum(`${tempSum}kr`);
            
            }
            else 
            {
                setActiveButtonToCart(false);
                console.log("You dont have a cookie!");
            }
        
        
        };

        handleCookieUpdate();
        setUpdateCookie(false);

        

    }, [updateCookie]);




    return(

        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width: "600px"}}>
            <div class="offcanvas-header" style={{backgroundColor: "#a2a2a261",borderBottom: "1px solid black"}}>
                <h2 id="offcanvasRightLabel">Varukorg</h2>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body py-5">
                {cartItems.length > 0 && cartItems.map((item) => (
                    <Cartitem key={item.id} productId={item.id} name={item.name} price={`${item.price}kr`} image={item.image} amount={item.quantity.toString()} />
                ))}
            </div>
        
            <div className="offcanvas-footer" style={{backgroundColor: "#a2a2a261", height: "200px", width: "auto"}}>
                <div className="row justify-content-center pt-2 pb-4">
                    <div className="d-flex justify-content-between" style={{width: "85%"}}>
                        <h2>Total Summa: </h2>
                        <h2 className={`total-sum ${animatePrice ? 'highlight-animation' : ''}`} onAnimationEnd={() => setAnimatePrice(false)}>{cartTotalSum}</h2>
                    </div>
                </div>

                <div className="row justify-content-center pb-2">
                        <div className="col-10 pb-2">
                            <button type="button" class="btn btn-lg btn-secondary" data-bs-dismiss="offcanvas" style={{width: "100%"}}>FORTSÄTT HANDLA</button>
                        </div>
                
                        <div className="col-10">
                            <button disabled={!activeButtonToCart} type="button" class="btn btn-lg btn-success" data-bs-dismiss="offcanvas" style={{width: "100%"}} onClick={() => handleNav("/checkout")}>TILL KASSA</button>
                        </div>
                
                
                </div>


            </div>
        
        </div>



    );


    }


    export default Cartcanvas;


