import React, { useCallback, useEffect, useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js';
import Cartitem from '../components/cartproduct';
import { getCookie, eventEmitter, saveCartToCookie, setCookie, getCartFromCookie } from '../cookie/cartCookie';
import './checkoutpage.css';
import { json } from 'react-router-dom';


const stripePromise = loadStripe("pk_live_51OMKoHGuYiSKOF0WxeiImIX1c0vnTHJzADDcswLsN1GaTA8vXTwK5QSpTWbWVVePTSNtJ0IwR3ctpMq3FLfacVxF00IEMDngQB", { locale: 'sv' });

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [products, setProducts] = useState(null);
  const [updatedCookie, setUpdateCookie] = useState(false);
  const [localPrice, setLocalPrice] = useState(0);
  const [localShippingCost, setLocalShippingCost] = useState(69);
  const [localTotalCost, setLocalTotalCost] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [componentKey, setComponentKey] = useState(0);
  const [renderModal, setRenderModal] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [pendingButton, setPendingButton] = useState(false);
  

  // const fetchClientSecret = useCallback(async () => {
  //   let body = JSON.parse(getCookie("tlCart"));
  //   try 
  //   {
  //     const response = await fetch("http://localhost:8000/create-checkout-session", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(body),
  //     });
  //     const data = await response.json();
  //     console.log(data.clientSecret);
  //     setClientSecret(data.clientSecret);
      
  //   } 
  //   catch (error) 
  //   {
  //     console.error('Failed to fetch client secret:', error);
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("CALLED");
  //   fetchClientSecret(); // Call this function on component mount and when the cart updates
  // }, [updatedCookie]);

  // -----------------------------------------------------------------------------------------------------------
  const fetchClientSecret = async () => {
    let body = JSON.parse(getCookie("tlCart"));
    
    try {
      const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      console.log(data.clientSecret);
      setClientSecret(data.clientSecret);
      setComponentKey(prevKey => prevKey + 1);
    } 
    catch (error) {
      console.error('Failed to fetch client secret:', error);
    }
  };
  
  

  const openModal = async() => 
  {
    const verify = async()=>{
      
          const cartStatus = await verifyCart();
          console.log("CART STATUS::", cartStatus.cart);
          if(cartStatus.needUpdate === "true")
          {
            
            setCookie("tlCart", JSON.stringify(cartStatus.cart), 7);
            setShowNoti(true);
            
          }
    
    
    
    
        };
    
    setPendingButton(true)
    await verify();
    setPendingButton(false);
    
    
    
    
    fetchClientSecret();




  }


  // -----------------------------------------------------------------------------------------------------------

  
  useEffect(() => {
    // Define the function inside useEffect to ensure it has access to the current state
    const updateItemCounter = () => {
        setUpdateCookie(true);
    };
    
    // Add the event listener
    eventEmitter.on('updatedCookie', updateItemCounter);

    // Return a cleanup function that removes the event listener
    return () => {
        eventEmitter.off('updatedCookie', updateItemCounter);
    };
  }, []); // Empty dependency array to run only on mount and unmount


  useEffect(() => 
  {
    
    
    let getCookieProducts = () => 
    {
        let cookie = getCookie("tlCart");
        if(cookie)
        {
            let currentCart = JSON.parse(cookie);
            const items = Object.keys(currentCart).map((productId) => ({
                ...currentCart[productId],
                id: productId
            }));
            setProducts(items);
        }
    }
    
    getCookieProducts();
    setUpdateCookie(false);
    
    


            
  }, [updatedCookie]);

  const verifyCart =async() =>{

    
    console.log(JSON.stringify(products));
    const currCart = getCookie("tlCart");  
    console.log("CURRCART: ", currCart);  

    const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/verify-in-stock", {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: currCart,
    });
    const data = await response.json();

    return data;
  
  };





  // useEffect(() =>{

  //   const verify = async()=>{
      
  //     const cartStatus = await verifyCart();
  //     console.log("CART STATUS::", cartStatus.cart);
  //     if(cartStatus.needUpdate === "true")
  //     {
        
  //       setCookie("tlCart", JSON.stringify(cartStatus.cart), 7);
  //       setShowNoti(true);
        
  //     }




  //   };
  //   verify();






  // },[updatedCookie])




  useEffect(() => 
  {
    const calculateLocalPrice = async() =>
      {
        
      let totPrice = 0;
      for(let i = 0; i < products.length; i++)
      {
        totPrice += products[i].quantity * products[i].price;
      }


      setLocalPrice(totPrice);
      if(totPrice > 0)
      {
        setLocalTotalCost(totPrice + localShippingCost);
      }
      else if(totPrice === 0)
      {
        setButtonDisabled(true);
        setLocalTotalCost(0);
      }

    } 
    if(products) {
      calculateLocalPrice();
    }

  }, [products]);


  useEffect(() => {
    if (showNoti) {
      const timer = setTimeout(() => {
        
        setShowNoti(false);
      }, 4000); // 2000ms = 2 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [showNoti]);



  return (

    
            
    <div className='container py-lg-5 py-2' style={{position: "relative"}}>

      

        <div className='row justify-content-center'>
            <div className='col-lg-10 col-11 d-flex flex-column justify-content-between' style={{backgroundColor: "white", borderRadius: "10px"}}>
                <div className='d-flex flex-column' style={{overflow: "scroll", maxHeight: "700px", minHeight: "400px"}}>
                    <h3 className='text-center pt-2 pb-3'>Din beställning</h3>
                    
                    {products !== null && products.length > 0 && products.map((item) => (
                        <div  className='d-flex w-100'>
                        <Cartitem key={item.id} productId={item.id} name={item.name} price={`${item.price}kr`} image={item.image} amount={item.quantity.toString()} />
                        </div>
                    ))}
                    
                
                </div>
                
                <div className='row d-flex' style={{ backgroundColor: "#e2e2e2",borderTop: "2px solid black"}}>
                  <p class="text-start my-0 pt-2 fs-5">Delsumma {localPrice}kr</p>
                  <p class="text-start my-0 fs-5">Fraktkostnad {localShippingCost}kr</p>
                  <p class="text-start my-0 fs-3 fw-bold">Att betala {localTotalCost}kr</p>    
                  
                
                </div>
                
                  <div className="row" style={{backgroundColor: "#e2e2e2", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                      <div className="d-flex justify-content-center py-2">

                        {/* <button type="button" disabled={isButtonDisabled} onClick={openModal} className='btn btn-lg btn-success btn-block w-50'  data-bs-toggle="modal" data-bs-target="#paymentModal" > Till Betalning  ()</button>                                    */}
                        <button 
      type="button" 
      disabled={isButtonDisabled} 
      onClick={openModal} 
      className='btn btn-lg btn-success btn-block w-50'  
      data-bs-toggle="modal" 
      data-bs-target="#paymentModal"
    >
      {pendingButton ? (
        <>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </>
      ) : (
        "Till Betalning"
      )}
    </button>

                      </div>
                  </div>                       
            </div>
        </div>





        <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              
              {showNoti && (

<div className='row d-flex justify-content-center notianim'>
  <div className='col-lg-10 col-11' style={{borderBottom: "1px solid black",backgroundColor:"rgba(135, 127, 127, 0.77", borderRadius: "10px"}}>

    <p className='fs-5 m-0 fw-bold text-center'>Tyvär, en eller flera valda varor överskrider vårt lagersaldo.</p>
    <p className='fs-5 m-0 fw-bold text-center'>Antalet sänks automatiskt.</p>

  </div>
  
  </div>
// <div className='d-flex justify-content-center align-items-center' style={{width: "90%", backgroundColor:"rgba(135, 127, 127, 0.77)", borderRadius: "10px"}}>
  
// </div>

)}


                 <div id="modalbodycontainer" class="modal-body">
                 <EmbeddedCheckoutProvider key={componentKey} stripe={stripePromise} options={{clientSecret}}>
                   <EmbeddedCheckout > </EmbeddedCheckout>
                 </EmbeddedCheckoutProvider>
                </div>
               
               
             
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ändra beställning</button>
              </div>
            </div>
          </div>
        </div>



    </div>

    
      
  );
};

export default CheckoutForm;
