import "./footer.css"
import imglogo from "../pictures/transparen_logo.png";
import { useNavigate } from "react-router-dom";
import Buyagreements from "./buyagreements";


const Footer = () => {
    
    const navigate = useNavigate();

    const handleNav = (to) => {
        navigate(to);
    };


    
    
    return (
        <footer>
            <Buyagreements></Buyagreements>


            <div className="py-5" style={{ backgroundColor: "black", color: "white" }}>
                <div className="container-md px-5 p-md-0">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="d-none d-md-block col-md-4 col-lg-3">
                            <img className="img-fluid" src={imglogo}></img>
                            
                            
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-3">
                            <div className="d-flex gap-1 justify-content-center">
                                <div className="d-flex flex-column">
                                    <h4 onClick={() => handleNav("/support")} type="button" className="bi bi-question-circle pt-2 pb-1 fs-4 fw-bold"> Hjälp & Kontakt</h4>
                                    <h5 className="fs-5" type="button" data-bs-toggle="modal" data-bs-target="#buymodal">Köp- & leveransvillkor</h5>
                                    <h5 className="fs-5">Betalning & säkerhet</h5>                                    
                                    <h5 onClick={() => handleNav("/about")} type="button" className="fs-5">Om oss</h5>
                                    <figcaption class="pt-3 blockquote-footer">
                                        support@tlfiske.se 
                                    </figcaption>
                                </div>
                            </div>
                        </div>
                       
                      
                        <div className="d-md-none d-flex flex-column">
                            <div className="d-flex flex-column">
                                <button className="d-flex justify-content-between align-items-center border-bottom mt-3 mb-2 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#help" aria-expanded="false" aria-controls="help" style={{ backgroundColor: "black", color: "white", border: "0px" }}>
                                    <h5>Hjälp & Kontakt</h5>
                                    <h5>+</h5>
                                </button>
                                <div class="collapse" id="help">
                                    <div className="d-flex flex-column">
                                        <a onClick={() => handleNav("/support")} type="button">Kontakt</a>
                                        <a type="button" data-bs-toggle="modal" data-bs-target="#buymodal">Köp- & leveransvillkor</a>
                                        <a>Betalning & säkerhet</a>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column pb-4">
                                <button className="d-flex justify-content-between align-items-center border-bottom mt-2 mb-2 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#info" aria-expanded="false" aria-controls="info" style={{ backgroundColor: "black", color: "white", border: "0px" }}>
                                    <h5>Vi är TlFiske</h5>
                                    <h5>+</h5>
                                </button>
                                <div class="collapse" id="info">
                                    <div className="d-flex flex-column">
                                        <a onClick={() => handleNav("/about")} type="button">Om oss</a>
                                        <a onClick={() => handleNav("/support")} type="button">Kontakta</a>
                                    </div>
                                </div>
                            </div>
                            <figcaption class="blockquote-footer">
                                        support@tlfiske.se 
                            </figcaption>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: "#32373a", color: "white", }}>
                <div className="col-10 d-flex justify-content-center my-4">
                    <div className="col-10 d-flex flex-column align-items-center">
                        <h6>Säker betalning med:</h6>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <img src="https://tlfiske.se/Payment_icons/Apple_Pay_Mark_RGB_041619.svg" style={{ width: "45px", height: "auto" }}></img>
                            <img src="https://tlfiske.se/Payment_icons/Marketing_Badge_With_Clear_Space.png" style={{ width: "80px", height: "auto" }}></img>
                            <img src="https://tlfiske.se/Payment_icons/adyen-visa.svg" style={{ width: "40px", height: "auto" }}></img>
                            <img className="ms-1" src="https://tlfiske.se/Payment_icons/ma_symbol.svg" style={{ width: "40px", height: "auto" }}></img>
                            <img className="ms-1" src="https://tlfiske.se/Payment_icons/swish.png" style={{ width: "65px", height: "auto" }}></img>

                        </div>
                    </div>
                </div>
                <div className="col-10 d-flex justify-content-center my-4">
                    <div className="col-10 d-flex flex-column align-items-center">
                        <h6>Vi levererar med:</h6>
                        <div>
                            <img src="https://tlfiske.se/Payment_icons/postnord.webp" style={{ width: "80px", height: "auto" }}></img>

                            {/* <img src="https://tlfiske.se/Payment_icons/DHL_Group_logo_rgb.png" style={{ width: "40px", height: "auto" }}></img> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


export default Footer;