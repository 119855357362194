import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant" // ensure behavior is set to 'auto' for instant jump
        });
    }, [pathname]);

    return null;
}





