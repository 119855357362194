import img1 from "../pictures/1.png"
import img2 from "../pictures/2.jpg"
import img3 from "../pictures/3.jpg"
import img4 from "../pictures/header.jpg"
import Aboutslide from "../components/aboutslider";
import './aboutpage.css'
import { useEffect, useRef } from "react";




function Aboutpage()
{
   
    const listenToScroll = () => 
    {
        console.log("Scrolled");




    }








    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-start p-0 m-0" >
                    <div className="hero-image">
                        <div className="hero-text">
                            <p className="hero-text2">Hooked on <br/> Adventure</p>
                                
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12  align-items-center  d-flex flex-column textholder-2">
                    <p className="hero-text3  pt-5"> Vilka är Tlfiske?</p>
                    <div className="upper-text-holder">
                        <p className="hero-text4   pt-4 pb-5 text-center"> 
                            Från ett passionerat fiskelag, Team Livboj, föddes TLfiske – din plattform för handtillverkade beten och ett hav av fiskeupplevelser. Vi grundades av en grupp entusiastiska fiskare som delade en gemensam vision: att erbjuda inte bara utrustning, utan en verklig upplevelse för alla som delar vår passion för sporten.    
                        </p>
                    </div>
                   

                </div>
            </div>


            <div className="row">
                <div className="col-md-6 col-12 p-0">
                    <img className="side-image-1"></img>
                </div>
                <div className="col-md-6 col-12 d-flex justify-content-center" style={{backgroundColor: "#2b2727"}}>
                    <div className="d-flex flex-column align-items-start w-75 pt-md-5 pt-0">
                            <p className="hero-text5 pt-5 text-left"> Passionen för fiske</p>
                            <p className="hero-text4 text-left pb-4">
                            Vi har fiskat i hela våra liv och delar passionen kring fisket. Våran idé började med att våra jiggar blev förstörda av gäddor vilket resulterade i att vi fick idén att börja tillverka egna. Vi handmålar våra större beten och designar form, färg samt gör jiggarna helt från grunden då vi vill ha en unik design som kan fånga både våran och din drömfisk.
                            
                            
                            </p>

                    </div>

                </div>

            </div>

            <div className="row">
                
                <div className="col-md-6 col-12 order-2 order-md-1 d-flex  justify-content-center" style={{backgroundColor: "#2b2727"}}>
                        <div className="d-flex flex-column align-items-start w-75 pt-md-5 pt-0">
                            <p className="hero-text6 pt-5"> Från tanke till verklighet</p>
                            <p className="hero-text4 pt-4 text-left">
                                På TLfiske tar vi våra idéer från skissbordet till vattnet. Vi formar våra egna betesmodeller, gjuter dem noggrant och experimenterar med färger för att skapa effektiva och unika beten. Varje steg i vår process är en resa av kreativitet och passion för att leverera det bästa till våra kunder och fiskare världen över.
                            </p>
                        
                            <figcaption class="text-end pt-3 blockquote-footer pb-4">
                                Vi önskar dig ett riktigt skitfiske!
                            </figcaption>
                        </div>

                </div>
                    
                    
                <div className="col-md-6 col-12 p-0 order-1 order-md-2">
                        <img className="side-image-2"></img>
                </div>
                

            </div>

            
          
               


            
           


           





        </div>


    );




}

export default Aboutpage;


