import logo from "../pictures/transparen_logo.png"
import "./supportpage.css"
import { useRef, useEffect, useState } from "react";








function SupportPage()
{
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [watingForServer, setWaitingForServer] = useState(false);
    const [orderId, setOrderId] = useState("");


    // Email validation function
    const validateEmail = (email) => 
    {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    // Name validation function (can be adjusted based on your requirements)
    const validateName = (name) => 
    {
        return name.trim().length > 0;
    };

    useEffect(() => {
        // Update form validity
        setIsFormValid(validateEmail(email) && validateName(name) && text.length >0);
    }, [email, name, text]); // This will run every time `email` or `name` changes
    
    // Input change handler
    const handleChangeName = (event) => 
    {
        const value = event.target.value;
        setName(value);
    };
    
    const handleChangeEmail = (event) => 
    {
        const value = event.target.value;
        setEmail(value);
    }

    const handleChangeText = (event) => 
    {
        const value = event.target.value;
        setText(value);
    }
    const handleOrderId = (event) =>
    {
        const value = event.target.value;
        setOrderId(value);
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();
        setWaitingForServer(true);
        const body = {
            email: email,
            name: name,
            text: text,
            orderId: orderId,
        }
        const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        });
        
        let data = await response.json();
        
        if(data.success == true)
        {
            setTimeout(() => setWaitingForServer(false), 1000);   
            setEmail("");
            setName("");
            setText("");
            setOrderId("");
        }

    }








    return(
        <div className="container py-5 ">
            <div className="row flex-lg-row flex-column-reverse  d-flex justify-content-center align-items-lg-stretch align-items-md-stretch align-items-center">
                <form onSubmit={handleSubmit} className="col-lg-5 col-md-12 col-11 d-flex justify-content-lg-end justify-content-center align-items-start py-5 custom-lg-class" style={{backgroundColor: "#00000021", height: "600px",  position: "relative", overflow: "hidden"}}>
                    {/* <div id="one" className="floating-circle"  ></div> */}
                    {/* <div id="two" className="floating-circle"  ></div> */}
                    {/* <div id="one" className="floating-circle"  ></div> */}
                    {
                        !watingForServer && (
                        <div className="d-flex flex-column w-75">
                            <h2 className="text-center"> Kontakta Oss </h2>
                            <input className="form-control mb-2" placeholder="Namn" style={{borderRadius: "15px"}}  value={name} onChange={handleChangeName}></input>
                            <input className="form-control mb-2" type="Email" placeholder="Email" style={{borderRadius: "15px"}} value={email} onChange={handleChangeEmail}></input>
                            <input id className="form-control" placeholder="Ordernummer (frivilligt)" style={{borderRadius: "15px", marginBottom: "80px"}}  onChange={handleOrderId} ></input>
                            <textarea className="form-control mb-2" placeholder="Behöver du hjälp?" style={{borderRadius: "15px", height: "170px", resize: "none"}} value={text} onChange={handleChangeText}  ></textarea>

                            <button
                                className={`btn ${isFormValid ? 'btn-primary' : 'btn-secondary'}`}
                                type="submit"
                                disabled={!isFormValid}
                                style={{ borderRadius: "15px" }}
                                >
                                Skicka
                            </button>

                            


                        </div>
                    )
                    }
                    {
                        watingForServer && 
                        (
                            <div className="d-flex flex-column w-75" >
                                <h2 className="text-center pb-5"> Kontakta Oss </h2>
                            
                               

                                <div class="d-flex  pt-5 justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>



                            </div>
                            
                        )
                    }
                        

                </form>

                <div className="col-lg-6 col-md-12 col-11 d-flex flex-column justify-content-start align-items-center pt-5 custom2-lg-class" style={{backgroundColor: "#00000021"}}>
                    
                    <div className="d-flex flex-column align-items-center mb-3 px-2" style={{backgroundColor:"#e2e2e2", width: "90%", height: "40%", borderRadius: "15px"}}>
                        <h3 className="text-center pt-2">Frågor kring din beställning?</h3>                       
                        <p className="text-center">På Tlfiske värdesätter vi din feedback högt, oavsett om det gäller våra produkter eller din upplevelse med en beställning. </p>
                        <p className="text-center"> Din åsikt bidrar till vår utveckling och förbättring. Tack för att du delar med dig av den till oss!</p>
                    </div>
                    <div className="d-flex justify-content-center" style={{height:"60%"}}>
                        <img src={logo} className="img-fluid "></img>

                    </div>
                </div>


            </div>    
        


        </div>


    )


}


export default SupportPage;

