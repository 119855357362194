import './productinslider.css'
import { useNavigate } from 'react-router-dom';
import {useInView} from 'react-intersection-observer';



function Productinslider(props){

    
    const data = props;
    
    const discount = data.product.discount === "true";
    const discountPrice = data.product.discountPrice;
    const img = data.product.image;
    const price = data.product.price;
    const name = data.product.name;
    const productId = data.product.prodID
    const { ref, inView } = useInView({
        threshold: 0.2, // Adjust threshold as needed
    });
   
    
    const navigate = useNavigate();

   


    const navToProduct = () => {
        navigate(`/product/${name}`, { state: { prodID: productId } });
    };

  


    return(
      
        <div  style={{maxWidth: "100%", height: "auto", borderRadius: "10px"}} type="button" onClick={navToProduct}>
            <div style={{maxWidth: "100%", height: "auto", borderRadius: "10px", position: "relative"}}>
                
                <img 
                     ref={ref}
                     className="img-fluid"
                     src={img}
                     style={{ borderRadius: '10px', display: 'block' }}
                     alt="Product Image">
                </img>
                
                
                <div className='overlay overlaytwo'> 
                    <div className='to-product text-center'>
                        <b className='text-center'>Till Produkt</b>
                    </div>
                </div>

            </div>
            
            <div className='d-flex flex-column justify-content-center align-items-center py-1'>
                <p 
                className="h3 text-center">
                    {name}
                </p>
                

                {!discount ? (
                    <>
                    {price !== undefined ? <p className="text-center" style={{margin: 0}}>{price} kr</p> : null}
                    
                    </>

                ) : (
                    <>
                    {price !== undefined ? <p className="text-center" style={{margin: 0, textDecoration: "line-through"}}>{price} kr</p> : null}
                    {discountPrice !== undefined ? <p className="text-center" style={{margin: 0, color: "red"}}>{discountPrice} kr</p> : null}
                    
                    </>

                )}


            </div>
        
        </div>
    
    );

}

export default Productinslider;