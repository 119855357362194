import "./productpage.css";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {getCookie, setCookie} from "../cookie/cartCookie"
import ImageGallery from "react-image-gallery"; 
import { useLocation } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";




function Productpage()
{
    const {productId} = useParams();
    const [name, setName] = useState("");
    const [image, setImage]  = useState();
    const [images, setImages] = useState([]);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [desc, setDesc] = useState();
    const [price, setPrice] = useState();
    const [currency, setCurrency] = useState("");
    const [itemAmount, setItemAmount] = useState(1);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [colors, setColors] = useState([]);
    const [currProductID, setCurrProductID] = useState("");
    const [currentColor, setCurrentColor] = useState("");
    const [specs, setSpecs] = useState({weight: "", length: ""});
    const galleryRef = useRef(null);
    const [addedToCart, setAddedToCart] = useState(false);
    const [isDiscounted, setIsDiscounter] = useState(false);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [inventoryBalance, setInventoryBalance] = useState(0);
    const [outOfStock, setOutOfStock] = useState(false);
    const [toLargeQuant, setToLargeQuant] = useState(false);
  
    const location = useLocation();
    const  pressedProdID = location.state || {};
                            


    const addToCart = (e) =>
    {
       
        
        e.preventDefault();
        setAddedToCart(true);
        if(itemAmount > 0)
        {
            let cookie = getCookie("tlCart");
            let currentCart;

            if(!cookie)
            {
                currentCart = {};
            }
            else
            {
                currentCart = JSON.parse(cookie);
            }

            if(!currentCart[currProductID])
            {
                let tmpPrice = isDiscounted ? discountedPrice : price;
                

                currentCart[currProductID] = {
                    quantity: parseInt(itemAmount),
                    image: image,
                    name: name,
                    price: tmpPrice
                };
            }
            else
            {
                currentCart[currProductID].quantity += parseInt(itemAmount);
            }
            setCookie("tlCart", JSON.stringify(currentCart), 30);
          
        }

    }

    
    
    
    
    


    useEffect(() => 
    {
        const getInfo = async() => 
        {
            try
            {
               
                const productNameFetch = encodeURIComponent(productId);
                const response = await fetch(`https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/fetch-product?name=${productNameFetch}`);
                const data = await response.json();
                const itemArray = data.data;

               

                if(itemArray.length > 0)
                {
                    const index = itemArray.findIndex(product => product.prodID === pressedProdID.prodID);
                    

                    
                    setStartIndex(index);
                    setName(itemArray[index].name);
                    setDesc(itemArray[index].desc);
                    setImage(itemArray[index].image);
                    setPrice(itemArray[index].price);
                    setCurrProductID(itemArray[index].prodID);
                    setCurrentColor(itemArray[index].color);
                    setSpecs({weight: itemArray[index].specWeight, length: itemArray[index].specLength});
                    setIsDiscounter(itemArray[index].discount === "true");
                    setDiscountedPrice(itemArray[index].discountPrice);
                    setInventoryBalance(parseInt(itemArray[index].inventoryBalance));
                    parseInt(itemArray[index].inventoryBalance) <= 0 ? setOutOfStock(true) :  setOutOfStock(false);
                   
                
                }
                
                
                let tempColors = [];
                
                for(let i = 0; i < itemArray.length; i++)
                {
                    let discounted = itemArray[i].discount === "true";
                    let discounterPrice = discounted ? itemArray[i].discountPrice : 0;
                    
                    tempColors.push({color: itemArray[i].color, image: itemArray[i].image, id: itemArray[i].prodID, price: itemArray[i].price, discount : discounted, discounterPrice : discounterPrice, inventoryBalance: itemArray[i].inventoryBalance});
                }
                setColors(tempColors);
                




            }
            catch(error)
            {
                console.log("Failed to fetch product!");
            }



        };
        getInfo();



        
        


    }, 
    []);


    useEffect(() => {

        if(colors.length > 0)
        {
            let tempImages = []
            for(let i = 0; i < colors.length; i++)
            {
                
                const obj = {
                    original: colors[i].image,
                    thumbnail: colors[i].image,
                }
            tempImages.push(obj);
            }
            setImages(tempImages);
            setApiLoaded(true);
        }



    },[colors]);



  
    const handleChange = (option) => 
    {
        const selectedIndex = colors.findIndex(color => color.color === option.color);
        if (galleryRef.current) {
            galleryRef.current.slideToIndex(selectedIndex);
        }
        setImage(option.image);
        setCurrProductID(option.id);
        setPrice(option.price);
        setCurrentColor(option.color);
        setIsDiscounter(option.discount);
        setDiscountedPrice(option.discounterPrice);
        setInventoryBalance(parseInt(option.inventoryBalance));
        parseInt(option.inventoryBalance) <= 0 ? setOutOfStock(true) : setOutOfStock(false);
    }

    const sliderChange = (e)=>
    {
        setAddedToCart(false);
        setImage(colors[e].image)
        setCurrProductID(colors[e].id);
        setPrice(colors[e].price);
        setCurrentColor(colors[e].color);
        setIsDiscounter(colors[e].discount);
        setDiscountedPrice(colors[e].discounterPrice);
        setInventoryBalance(parseInt(colors[e].inventoryBalance));
        parseInt(colors[e].inventoryBalance) <= 0 ? setOutOfStock(true) : setOutOfStock(false);
       
    }

    useEffect(() => {
        // Set buttonPressed back to false after 3000 milliseconds (3 seconds)
        const timer = setTimeout(() => {
          setAddedToCart(false);
        }, 2000);
    
        // Clean up the timer to prevent memory leaks
        return () => clearTimeout(timer);
      }, [addedToCart]);

  
        



    return(
        
        
        
        <div className="container pt-4 pb-5">

            {apiLoaded ? (
                <>
            

            <div className="row justify-content-center">
                <div className="col-6 justify-content-center d-flex" style={{backgroundColor: "red"}}>
                </div>
            </div>



            <div className="row justify-content-center" style={{ borderRadius: "6px"}}>
                <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">                            
                        <ImageGallery ref={galleryRef} items={images} onSlide={e => sliderChange(e)} showNav={false} startIndex={startIndex} showPlayButton={false} showIndex={true} showBullets={true}/>
                    
                </div>
                <div className="col-lg-5 col-md-12 col-11" style={{backgroundColor: "white", position: "relative", overflow: "hidden", borderRadius: "10px"}}>
                        <div className="tilter"></div>
                        <div className="d-flex flex-column">
                            <h1 className="fonter-text ps-3 pt-3" style={{zIndex: 2, fontWeight: "550"}}>{name}</h1>
                            
                            {!isDiscounted ? (
                                <>
                                    <p className="fs-4 fonter-text ps-4 pt-1" style={{zIndex: 2, fontWeight: "550", margin: "0", color: "#2d2d2dc9"}}>{price} kr</p>
                                </>

                            ): (
                                <>
                                    <p className="fs-5 fonter-text ps-4 pt-1" style={{textDecoration: "line-through",zIndex: 2, fontWeight: "550", margin: "0", color: "#2d2d2dc9"}}>{price} kr</p>
                                    <p className="fs-4 fonter-text ps-4 pt-1" style={{zIndex: 2, fontWeight: "550", margin: "0", color: "red"}}>{discountedPrice} kr</p>
                                    
                                    
                                </>
                            )}

                            


                        </div>
                        <div className="d-flex flex-column" style={{marginTop: "125px"}}>
                            
                            <div className="dropdown d-flex justify-content-center w-100 ">
                                <button className="btn btn-block btn-secondary dropdown-toggle dropdown-button d-flex align-items-center justify-content-between fs-5 py-2 w-100" type="button" data-bs-toggle="dropdown" style={{backgroundColor: "#e2e2e2", color:"black", border: "1px solid black"}}>
                                    Färg: {currentColor}
                                </button>
                                
                                
                                <ul className="dropdown-menu w-100">
                                {colors.map((option, index) => (
                                    <li key={index}>
                                        <a onClick={() => handleChange(option)} className="dropdown-item" href="#">{option.color}</a>
                                    </li>
                                ))}
                                 
                                </ul>
                            </div>                            
                        
                            <form className="input-group input-group-lg flex-nowrap pt-2 pb-1" onSubmit={addToCart} style={{position:"relative"}}>
                                    <input className="from-control ps-2" type="number" step="1" value={itemAmount} min={1} max={98} style={{width: "15%", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px"}} onChange={(e) => setItemAmount(e.target.value)}></input>
                                   
                                    <button className="btn btn-secondary btn-success w-100" type="submit" disabled={outOfStock || toLargeQuant}>
                                        Lägg i varukorg
                                    </button>
                                    <div className={addedToCart ? "added-to-cart" : "added-to-cart-not-pressed"}>
                                            <p className="py-2" style={{color: "white", padding: 0, margin: 0}}>Tillagd i varukorg!</p>
                                    </div>
                                
                                   
                            
                            
                            </form>

                            <div className="d-flex pt-1">
                                {!outOfStock ? (
                                    <div className="d-flex align-items-center justify-content-start w-100 gap-2" style={{backgroundColor: "#e2e2e2", borderRadius: "10px"}}>
                                        <p className="fs-5 ps-2 m-0">Lagersaldo</p>
                                        
                                        <div className="d-flex flex-row align-items-center gap-1">
                                            <span className="fs-5 text-center">{inventoryBalance}</span>
                                    
                                            <i style={{fontSize: "22px"}} class="bi bi-bag-check"></i>
                                        </div>
                                    </div>
                                ):(
                                    <div className="d-flex align-items-center justify-content-start w-100 gap-2" style={{backgroundColor: "#e2e2e2", borderRadius: "10px"}}>
                                    <p className="fs-5 ps-2 m-0">Varan är slut i lagret</p>
                                    
                                    <div className="d-flex flex-row align-items-center gap-1">
                                        <i style={{fontSize: "22px"}} class="bi bi-emoji-frown"></i>
                                        </div>
                                    </div>
                                )}




                            </div>

                            <div className="d-flex flex-column pt-5">
                            
                            

                            <button class="d-flex btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="true" aria-controls="collapseExample" style={{backgroundColor: "white", color:"black", border: 0, borderBottom: "1px solid black", borderRadius: 0}}>
                                <p className="fs-5" style={{margin: 0}}>Beskrivning </p>
                            </button>

                            <div class="collapse show" id="collapseExample1">
                                <div class="card card-body">
                                    <p className="fonter-text" style={{margin: 0}}> {desc} </p>
                                </div>
                            </div>


                            <button class="d-flex btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample" style={{backgroundColor: "white", color:"black", border: 0, borderBottom: "1px solid black", borderRadius: 0}}>
                                <p className="fs-5" style={{margin: 0}}>Specifikationer </p>
                            </button>

                            <div class="collapse show pb-3" id="collapseExample">
                                <div class="card card-body">
                                    <p className="fs-5 fw-normal fonter-text" style={{margin: 0}}> Vikt {specs.weight}g</p>
                                    <p className="fs-5 fw-normal fonter-text" style={{margin: 0}}> Längd {specs.length}cm</p>
                                    

                                </div>
                            </div>




                            </div>
                            
                            






                        
                        </div>





                </div>

            
            
            
            </div>

            </>
             ) : (
                <div className="row justify-content-center">
                    <div className="col-12 d-flex justify-content-center">
                        <div className="d-flex justify-content-center align-items-center" style={{width: "100%", height: "40vw"}}>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>

                        </div>


                    </div>
                </div>

              )}

                        
        </div> 


    );






}

export default Productpage;