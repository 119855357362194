


const Returnerrorpage = () => 
{


    return(
        <div className="container">
            <div className="row pt-5 ">
                <div className="col-12 d-flex justify-content-center">
                    <p className="h1">
                        Något verkar ha gått snett... 
                    </p>
                </div>
                
            </div>

            <div className="col-12 d-flex justify-content-center pt-4">
                <p className="fs-4 text-center">Din betalning har inte gått igenom och du har ej debiterats.</p>
            </div>

            <div className="col-12 d-flex justify-content-center pt-4">
                <p className="fs-5 text-center">Kontrollera din betalningsmetod och att du har täckning på kontot. <br></br>Vid upprepade problem kontakta din bank eller vår kundsupport.</p>
                
            </div>

            <div className="col-12 d-flex justify-content-center pt-4 pb-5">
                <p className="fs-5 fw-bold text-center"> support@tlfiske.se </p>

            </div>

            




            
        </div>

    );









};


export default Returnerrorpage;
