import "./checkoutitem.css";







const CheckoutItem = (props) => 
{

    return(
        
        <div className="container-fluid">
            <div className="row pb-3 large-monitor-disp">
                <div className="col-7 ps-4">
                    <div className="d-flex">
                        <img className="img-thumbnail" style={{width: "175px"}} src={props.image}></img>
                        <h4 className="text-left ps-4 pt-3">{props.name}</h4>
                    </div>
                </div>
                <div className="col-3 d-flex align-items-start pt-3 justify-content-start ps-4" >
                    <p className="text-center" style={{margin: 0}}>x{props.quantity}</p>
                </div>
                <div className="col-2 d-flex align-items-start pt-3 justify-content-center pe-5">
                    <p className="text-center" style={{margin: 0}}>{props.amount} sek</p>
                </div>
            </div>


            <div className="row small-monitor-disp">
                <div className="col-7 d-flex ps-5">
                    <img className="img-thumbnail" style={{width: "70%"}} src={props.image}></img>
                </div>

                <div className="col-3 d-flex justify-content-start">
                <p className="text-center" style={{margin: 0}}>x{props.quantity}</p>
                </div>

                <div className="col-2 d-flex justify-content-center"> 
                <p className="text-center" style={{margin: 0}}>{props.amount}  <br/>sek</p>
                </div>

            </div>
        
        </div>



     




    )






} 


export default CheckoutItem;