import { useEffect, useState } from "react";
import {getCookie, setCookie, eventEmitter} from "../cookie/cartCookie";
import "./cartproduct.css";


function Cartitem(props)
{
    let name = props.name;
    let price = props.price;
    let image = props.image;
    let amount = props.amount;
    let productID = props.productId;
    const [currentAmount, setCurrentAmount] = useState(amount);
    const [newProductAmount, setNewProductAmount] = useState(false);

  

    useEffect(() => {
        // Define the function inside useEffect to ensure it has access to the current state
        const updateItemCounter = () => {
            setNewProductAmount(true);
        };
    
        // Add the event listener
        eventEmitter.on('updatedCookie', updateItemCounter);
    
        // Return a cleanup function that removes the event listener
        return () => {
            eventEmitter.off('updatedCookie', updateItemCounter);
        };
    }, []); // Empty dependency array to run only on mount and unmount
    



    useEffect( () => 
    {
        let tmpCookie = getCookie("tlCart");
        let currentCart = tmpCookie ? JSON.parse(tmpCookie) : null;
        
        if(currentCart && currentCart[productID])
        {
            setCurrentAmount(currentCart[productID].quantity);
        }


        setNewProductAmount(false);
    }, [newProductAmount]);



    const removeItems = ()=> 
    {
        let currentCart = JSON.parse(getCookie("tlCart"));

        console.log(currentCart);
        if(currentCart)
        {
            delete currentCart[productID];
            setCookie("tlCart", JSON.stringify(currentCart), 30);
        }
    }

    const incrementItem = () => 
    {
        let currentCart = JSON.parse(getCookie("tlCart"));
        if(currentAmount < 99 && currentAmount)
        {
            setCurrentAmount(parseInt(currentAmount) + 1);
            currentCart[productID].quantity += 1;
            setCookie("tlCart", JSON.stringify(currentCart), 30);
        }
    }

    const decrementItem = () => 
    {
        let currentCart = JSON.parse(getCookie("tlCart"));
        if(currentAmount > 1)
        {
            setCurrentAmount(parseInt(currentAmount) - 1);
            currentCart[productID].quantity -= 1;
            setCookie("tlCart", JSON.stringify(currentCart), 30);
        }    
    
    }

    const handleInputChange = (e) => 
    {
        const newValue = e.target.value;
        const newAmount = parseInt(newValue, 10);
        
        // Allow the user to clear the input or type freely, but only update the cart for valid numbers within the range
        if (newValue === '') 
        {
            // Temporarily set the currentAmount state to an empty string to allow the input to be cleared
            setCurrentAmount(newValue);
        } 
        else if (!isNaN(newAmount) && newAmount >= 1 && newAmount <= 99) 
        {
            // Update the state and the cart when a valid number is entered
            setCurrentAmount(newAmount);

            let currentCart = JSON.parse(getCookie("tlCart"));
            if (currentCart && currentCart[productID]) {
                currentCart[productID].quantity = newAmount;
                setCookie("tlCart", JSON.stringify(currentCart), 30);
            }
        }


    }


    return(
    <div className="container-fluid pb-3">
        
        
        <div className="row">
            <div className="col-4">
                <img className="img-fluid" src={image} style={{borderRadius: "4px"}}/>
            </div>
            <div className="col-8 d-flex flex-column pt-2">
                <h3>{name}</h3>                
                <p className="fs-4">{price}</p>

                <div className="row justify-content-between">
                    <div className="col-4 ">
                        <form className="d-flex pt-1">
                            <i class="bi bi-dash-circle incDecPress pe-1" type="button" onClick={decrementItem} style={{fontSize: "24px"}}></i>
                            <input className="from-control ps-3" type="text" value={currentAmount} min={1} max={98} onChange={handleInputChange} style={{width: "50px", borderRadius: "10px"}} ></input>
                            <i class="bi bi-plus-circle incDecPress ps-1" type="button" onClick={incrementItem} style={{fontSize: "24px"}}></i>
                        </form>                       
                    </div>

                    <div className="col-lg-7 col-5 d-flex ms-lg-2 justify-content-end">
                        <i class="bi bi-trash3 incDecPress pe-md-5 pe-1" type="button" onClick={removeItems} style={{fontSize: "24px"}}></i>

                    </div>

                </div>
            </div>
          

        </div>

    </div>
    )







}


export default Cartitem;