import React, { useEffect, useState, useRef }  from "react";
import { useNavigate } from "react-router-dom";
import {getCookie, eventEmitter, setCookie} from "./cookie/cartCookie";
import './navbar.css';
import Box from "./test";
import logo from "./pictures/transparen_logo.png"

function Navbar() {
    const [itemCounter, setItemCounter] = useState(0);
    const [updateItemCounter, setUpdateItemCounter] = useState(true);
    const [bubbleVisible, setBubbleVisible] = useState(false);

    useEffect(() => {
        // Define the function inside useEffect to ensure it has access to the current state
        const updateItemCounter = () => {
            setUpdateItemCounter(true);
        };

        // Add the event listener
        eventEmitter.on('updatedCookie', updateItemCounter);
    
        // Return a cleanup function that removes the event listener
        return () => {
            eventEmitter.off('updatedCookie', updateItemCounter);
        };
    }, []); // Empty dependency array to run only on mount and unmount

    useEffect(()=>
    {
        let cookie = getCookie("tlCart");
        if(cookie)
        {
            let currentCart = JSON.parse(cookie);

            
            let value = 0;

            
            for (let key in currentCart) {
                if (currentCart.hasOwnProperty(key)) {
                    
                    value += currentCart[key].quantity;
                }
            }

            if(value >9)          
                setItemCounter(`9+`);
            else
                setItemCounter(value);

            setBubbleVisible(true);
        }
        else
        {
            setItemCounter(0);
            setBubbleVisible(false);
        }

        if(itemCounter === 0)
        {
            setBubbleVisible(false);
        }


        setUpdateItemCounter(false);
    },[updateItemCounter]);
    

    const verifyCart  = async() => 
    {

        const verifyToserver = async() =>{

            let body = JSON.parse(getCookie("tlCart"));
            if(body)
            {
                try {
    
                    const response = await fetch("https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod//verify-cart", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                    });
                    const data = await response.json();
                    return data.dumpCart;
                } 
                catch (error) {
                    console.error('Failed to verify cart:', error);
                }
            }

        };

        let det = await verifyToserver();
        if(det) // Delete invalid card.
        {
            let obj = {}
            setCookie("tlCart", JSON.stringify(obj), 30);            
        }
      
    
    }


    const handleIconClick = (platform) => {
        let url = '';
        switch(platform) {
          case 'Instagram':
            url = 'https://www.instagram.com/teamlivb0j/?igsh=cjQ4Z3pua3J5dm9p&utm_source=qr';
            break;
          case 'TikTok':
            url = 'https://www.tiktok.com/@tlfiske?_t=8nbulDXaEFs&_r=1';
            break;
          default:
            break;
        }
        window.open(url, '_blank');
      };




    const navigate = useNavigate();

    const handleNav = (to) => {
        navigate(to);
    };


    return (       
      <nav id="menu" className="navbar navbar-expand-lg navbar-light bg-light pt-0 pb-0">
        

        <div className="container-fluid navBarColor">
      
                <div className="col-2 d-none d-lg-flex flex-column  align-items-center">
                    
                    <div className="slide-text-background div-to-hide">
                        <p className="slide-text-one fs-3" style={{margin: 0}}> For Fishers</p>
                        <p className="slide-text-two fs-3" style={{margin: 0}}> By Fishers</p>
                        <div className="deg-cut"></div>
                    </div>

                    

                </div>


                <div className="col-8 d-flex d-none d-md-flex justify-content-center">
                <img src={logo} draggable="false" className="logo-navbar pe-1" type="button" onClick={() => handleNav("/")}></img>
                
                <div class="d-flex justify-content-center py-1 pe-5">
                    <div class="navbar-text" type="button" onClick={() => handleNav("/")}>
                        <i class="bi bi-house" style={{fontSize: "20px"}}></i>
                        <b style={{userSelect: "none"}}>Hem</b>
                    </div>
                </div>

                <div class="d-flex justify-content-center py-1 pe-5">
                    <div class="navbar-text" type="button" onClick={() => handleNav("/products")}>
                        <i class="bi bi-archive" style={{fontSize: "20px"}}></i>
                        <b style={{userSelect: "none"}}>Produkter</b>
                    </div>
                </div>

                <div class="d-flex justify-content-center py-1 pe-5">
                    <div class="navbar-text" type="button" onClick={() => handleNav("/about") }>
                        <i class="bi bi-info-circle" style={{fontSize: "20px"}}></i>
                        <b style={{userSelect: "none"}}>Om</b>
                    </div>
                </div>

                <div class="d-flex justify-content-center py-1 pe-5">
                    <div class="navbar-text" type="button" onClick={()=> handleNav("/support")}>
                        <i class="bi bi-file-person" style={{fontSize: "20px"}}></i>
                        <b style={{userSelect: "none"}}>Support</b>
                    </div>
                </div>
                  
                </div>

                
                <div class="col-2 d-flex d-none d-md-flex justify-content-end py-1 ">
                    <div class="d-flex justify-content-center gap-4 py-1 pe-3">

                        <div className="cartholder">
                            <i class="bi bi-cart3" type="button" onClick={verifyCart} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{fontSize: "40px"}}>
                                {bubbleVisible && (
                                <div className="cartnotify" key={itemCounter}>
                                    <p className="fs-6 text-center cartnotifyvalue" style={{padding: "0px", margin: "0px"}}>
                                        {itemCounter}
                                    </p>
                                </div>
                                )}
                            </i>
                        </div>
                        <i class="bi bi-instagram" type="button" onClick={() => handleIconClick('Instagram')} style={{fontSize: "40px"}}></i>
                        <i class="bi bi-tiktok" type="button" onClick={() => handleIconClick('TikTok')} style={{fontSize: "40px"}}></i>
                    </div>
                </div>

                {/* Small Screen */} 
                <div className="container-fluid d-sm-flex d-md-none">
                <div className="d-flex justify-content-between flex-row w-100">


                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasExampleLabel">
                    <div class="offcanvas-header" style={{backgroundColor: "#a2a2a261",borderBottom: "1px solid black"}}>
                        <h2 id="offcanvasRightLabel">Meny</h2>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div className="d-flex flex-column gap-4 ">
                            <div onClick={() => handleNav("/")} className="d-flex flex-row justify-content-between align-items-center flex-row" type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{borderBottom: "1px solid black"}}>
                                    <p className="fs-4 fw-normal" style={{userSelect: "none", margin: 0}}>Hem</p>
                                    <i class="bi bi-house" style={{fontSize: "32px"}}></i>
                            </div>

                            <div onClick={() => handleNav("/products")} className="d-flex flex-row justify-content-between align-items-center flex-row" type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{borderBottom: "1px solid black"}}>
                                    <p className="fs-4 fw-normal" style={{userSelect: "none", margin: 0}}>Produkter</p>
                                    <i class="bi bi-archive" style={{fontSize: "32px"}}></i>
                            </div>

                            <div onClick={() => handleNav("/about")} className="d-flex flex-row justify-content-between align-items-center flex-row" type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{borderBottom: "1px solid black"}}>
                                    <p className="fs-4 fw-normal" style={{userSelect: "none", margin: 0}}>Om</p>
                                    <i class="bi bi-info-circle" style={{fontSize: "32px"}}></i>
                            </div>


                            <div onClick={() => handleNav("/support")} className="d-flex flex-row justify-content-between align-items-center flex-row" type="button" data-bs-dismiss="offcanvas" aria-label="Close" style={{borderBottom: "1px solid black"}}>
                                    <p className="fs-4 fw-normal" style={{userSelect: "none", margin: 0}}>Support</p>
                                    <i class="bi bi-file-person" style={{fontSize: "32px"}}></i>
                            </div>

                        </div>

                    
                    </div>
                </div>


                    <div className="d-flex align-items-center w-25" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                        <button className="navbar-toggler">
                            <i class="bi bi-list" style={{fontSize: "36px"}}></i>
                        </button>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                        <img className="logo-navbar" src={logo} type="button" onClick={() => handleNav("/")} style={{width: "150px",height: "84px"}}></img>
                    </div>

                    <div className="d-flex align-items-center justify-content-center w-25 gap-3">
                        <div className="cartholder">
                            <i class="bi bi-cart3" type="button" onClick={verifyCart} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{fontSize: "40px"}} >
                                {bubbleVisible && (
                                <div className="cartnotify" key={itemCounter}>
                                    <p className="fs-6 text-center cartnotifyvalue" style={{padding: "0px", margin: "0px"}}>
                                        {itemCounter}
                                    </p>
                                </div>
                                )}
                            </i>
                        </div>
                        <i class="bi bi-instagram" type="button" onClick={() => handleIconClick('Instagram')} style={{fontSize: "40px"}}></i>
                    </div>
               
               
                </div>

                </div>





        </div>
            
      </nav>
    );
  }
  
  export default Navbar;

