import { useEffect, useState } from "react";
import CheckoutItem from "../components/checkoutitem";
import "./returnpage.css";
import gif from "../gifs/icons8-success.gif";
import { deleteCookie } from "../cookie/cartCookie";
import { useNavigate } from "react-router-dom";




const Returnpage = () => 
{
    const [orderName, setOrderName] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [currency, setCurrency] = useState("");
    const [adress, setAdress] = useState("");
    const [orderId, setOrderId] = useState(0);
    const [amount, setAmount] = useState("");
    const [subAmount, setSubAmount] = useState("");
    const [email, setEmail] = useState("");
    const [lineItems, setLineItems] = useState([]);
    const [renderSpinner, setRenderSpinner] = useState(true);


    const navigate = useNavigate();

    const handleNav = (to) => {
        navigate(to);
    };


    
    const getCurrentDate = () => 
    {
        const date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Adjust format options as needed
        return date.toLocaleDateString('en-SE', options);
    }
    
    const currentDate = getCurrentDate();

    useEffect(() => 
    {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        const fetchStatus = async() => 
        {
            try 
            {
                const response = await fetch(`https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/session-status?session_id=${sessionId}`, {
                    method: "GET"
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const data = await response.json();
                console.log("data : ",data);
                if(data.status === "complete")
                {
                    setOrderName(data.shipping_details.name);
                    setCity(data.shipping_details.address.city);
                    setPostalCode(data.shipping_details.address.postal_code);
                    setAdress(data.shipping_details.address.line1);
                    setCurrency(data.currency);
                    setOrderId(data.order_id);
                    setAmount((data.amount_total / 100).toFixed(2));
                    setSubAmount(data.amount_subtotal);
                    setEmail(data.customer_email);
                    deleteCookie("tlCart");
                }
                else
                {
                    handleNav("/returerror");
                }
            } 
            catch (error) 
            {
                console.error('Failed to fetch session status:', error);
            }
        }   


        const fetchItems = async() => 
        {
            try
            {
                const response = await fetch(`https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/session-items?session_id=${sessionId}`, {
                        method: "GET"
                });
            
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const data = await response.json();
                setLineItems(data.items.data);
                console.log(lineItems);




            }
            catch(error)
            {
                console.log(error);
            }
        }






        fetchStatus();
        fetchItems();
        



    },[])


    useEffect( () => {
        if(lineItems.length > 0 )
        {
            setRenderSpinner(false);
        }
    },[lineItems])





    return(

    <div className="container-fluid">
        
        

        {!renderSpinner ? (
        <div className="row py-lg-5 py-2  justify-content-center fade-in">
            <div className="col-lg-10 col-md-12 col-11" style={{backgroundColor: "white"}}>
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="holder" >
                            <div className="header-div">
                    
                            </div>


                            <div className="d-flex pt-3 mb-4 w-100 justify-content-center" style={{zIndex: 10, borderBottom: "1px solid #bbb8b8"}}>
                                <h3>TlFiske</h3>
                            </div>
                            <div className="d-flex w-100 justify-content-center mb-3" style={{zIndex: 10}}>
                                <img src={gif} style={{borderRadius: "60px"}}></img>
                            </div>
                            <div className="d-flex flex-column w-100 align-items-center " style={{zIndex: 10}}>
                                <p className="text-center" style={{fontWeight: "500"}}>Hej {orderName}</p>

                                <h2 className="text-center fw-bolder pb-3">Tack för din beställning!</h2>

                                <div className="d-flex p-2 justify-content-center align-items-center order-number-holder">
                                    <p className="text-center" style={{padding: 0, paddingRight: "5px", margin: 0}}>Order No:</p> 
                                    <p className="text-center" style={{padding: 0, margin: 0, fontWeight: 600}}>#{orderId}</p>
                                </div>

                                
                                <div className="row d-flex w-100 pt-lg-5 pt-4 justify-content-center ">
                                    <div className="col-lg-3 col-4  justify-content-center" >
                                        <p className="text-center fs-5" style={{color: "#717875", padding: 0, margin: 0}}>Datum</p>
                                        <p className="text-center">
                                            {currentDate}
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-4 justify-content-center">
                                        <p className="text-center fs-5" style={{color: "#717875", padding: 0, margin: 0}}>Adress</p>
                                        <p className="text-center" style={{margin: 0}}>
                                            {adress}
                                        </p>
                                        <p className="text-center" style={{margin: 0}}>
                                            {city} {postalCode}
                                        </p>

                                        
                                    </div>
                                    <div className="col-lg-3 col-4  justify-content-center">
                                        <p className="text-center fs-5" style={{color: "#717875",padding: 0, margin: 0}}>Total Summa</p>
                                        <p className="text-center">
                                            {amount} sek
                                        </p>
                                    </div>



                                </div>






                            </div>




                            


                        </div>

                        
                        
                            
                        
                        



                    </div>
                </div>
                
                <div className="row">
                    <h4 className="pt-3">Order summering</h4>
                    <div className="col-12">
                        <div className="d-flex w-100" style={{backgroundColor: "#e2e2e2"}}>
                            <div className="col-7 d-flex">
                                <p className="fs-3 ps-5" style={{margin: 0}}>Produkt</p>
                            </div>
                            <div className="col-3">
                            <p className="fs-3" style={{margin: 0}}>Antal</p>
                                
                            </div>

                            <div className="col-2 pe-4 d-flex justify-content-center">
                                <p className="fs-3" style={{margin: 0}}>Pris</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-12 pt-2" style={{borderBottom: "1px solid black"}}>
                        <div className="d-flex flex-column  w-100">
                        {lineItems.map((item) => (
                            <CheckoutItem key={item.id} name={item.description} amount={(item.amount_total / 100).toFixed(2)}  quantity={item.quantity} image={item.img}></CheckoutItem>
                            ))}


                        </div>


                    </div>


                    
                </div>
                
    
                






            </div>



        </div>
        ) : (
           
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{height: "40vw"}}>
                    <div class="spinner-border" role="status">
                         <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

            </div>


        )}
    

    </div>
    )

}



export default Returnpage;