


function ErrorPage()
{
    return(
        <div className="container">
            <div className="row justify-content-center pt-md-5 pt-0 pb-2">
                <div className="col-12 justify-content-center align-items-center d-flex">
                    <i class="bi bi-emoji-frown" style={{fontSize: "146px"}}></i>
                </div>
                <div className="col-12 justify-content-center d-flex">
                    <p className="h1 " style={{fontSize: "90px"}}>
                        404
                    </p>
                    
                </div>
            </div>

            <div className="row justify-content-center">
                
                <div className="col-12 justify-content-center d-flex">
                    <p className="h2">Sidan kunde inte hittas...</p>

                </div>


            </div>

            <div className="row pb-5 mb-5">
                <div className="col-12 d-flex justify-content-center">
                    <p className="fs-5">Kontrollera din URL (Länk)</p>
                </div>
            </div>






        </div>


    );




};



export default ErrorPage;