import EventEmitter from 'events';

export const eventEmitter = new EventEmitter();
eventEmitter.setMaxListeners(100);

// Function to add an item to the cart
export function addItemToCart(itemName, itemPrice) 
{
    let cart = getCartFromCookie(); // Retrieve cart from cookie

    // Check if item already exists in the cart
    const existingItem = cart.find(item => item.name === itemName);
    if (existingItem) {
        existingItem.quantity++; // Increment quantity if the item is already in the cart
    } else {
        // Add item to the cart object
        cart.push({
            name: itemName,
            price: itemPrice,
            quantity: 1 // Initial quantity
        });
    }

    saveCartToCookie(cart); // Save updated cart to the cookie
}

// Function to retrieve cart from cookie
export function getCartFromCookie() 
{
    let cart = [];
    const cartData = JSON.parse(getCookie('tlCart')); // Retrieve cart data from cookie
    if (cartData) {
        cart = cartData;
    }
    return cart;
}

// Function to save cart to cookie
export function saveCartToCookie(cart) 
{
    const cartData = JSON.stringify(cart);
    setCookie('cart', cartData, 7); // Save cart data to cookie (expiration in days)
}

// Function to set a cookie
export function setCookie(name, value, days) 
{
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;        
    }
    eventEmitter.emit('updatedCookie');
    document.cookie = `${name}=${value || ''}${expires}; path=/`;

}

// Function to get a cookie value
export function getCookie(name) 
{
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}


export function deleteCookie(cookieName) {
    eventEmitter.emit('updatedCookie');
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}