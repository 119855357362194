import React, {useState} from "react";
import './test.css';







function Box()
{
    const [value, setValue] = useState(0);


    return(
        <div className="col-3">
            <button onClick={() => setValue(value +1 )}>Up</button>
            {value}
        </div>   
    );

}



export default Box;