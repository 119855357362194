

function Adminlogin() {

    const onLoginSubmit = (e) => {
        e.preventDefault();
        const usr = e.target.elements[0].value;
        const pwd = e.target.elements[1].value 

        const apiCall = async(usr, pwd) => {
            const response = await fetch('https://vwlgpktz59.execute-api.eu-north-1.amazonaws.com/prod/hidden-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: usr, password: pwd }),
            });


            const respJson = await response.json();
            return {respJson, response};
        }
        
        apiCall(usr, pwd).then(({respJson, response}) => {
            if (respJson.success) {
                localStorage.setItem("tlToken", respJson.success);
                window.location.reload();
            } else {
                window.alert("FEL!");
            }
        });
    }

   



    return(
        <div className="container">
            <div className="row d-flex justify-content-center pt-5 pb-5 mb-5">
                <div className="col-4 mt-5 mb-5" style={{backgroundColor: "#919191", borderRadius: "10px"}}>
                        <form onSubmit={e => onLoginSubmit(e)} className="d-flex flex-column py-3">
                            <label>Användarnamn</label>
                            <input className="form-control" type="text"  required/>

                            <label>Lösen</label>
                            <input className="form-control mb-3" type="password"  required/>
                            
                            <button type="submit" className="btn btn-secondary">Logga in</button>
                        </form>
                </div>
            </div>
        </div>
    )
};



export default Adminlogin;