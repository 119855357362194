import { useState, useEffect } from "react";

function CookiePrompt() {
  const [isOpen, setIsOpen] = useState(false); // Start with modal closed

  useEffect(() => {
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('tlCookie='));
    if (cookieValue) {
      const value = cookieValue.split('=')[1];
      if (value === 'true') {
        setIsOpen(false); 
        loadHotjar(); 
      } else if (value === 'false') {
        setIsOpen(false); 
      } else {
        setIsOpen(true); 
      }
    } else {
      setIsOpen(true); 
    }
  }, []);

  // Function to set a cookie
  function setCookie(name, value, months) {
    const date = new Date();
    date.setTime(date.getTime() + (months * 30 * 24 * 60 * 60 * 1000)); // Set expiration
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`; // Create the cookie
  }

  
  const cookieDeny = () => {
    setCookie("tlCookie", "false", 1); // Set cookie to false for 1 month
    setIsOpen(false); // Close the modal
  }

  // Function for accepting cookies
  const cookieAccept = () => {
    setCookie("tlCookie", "true", 24); // Set cookie to true for 24 months
    setIsOpen(false); // Close the modal
    loadHotjar();
}

  
  const loadHotjar = () => {
    (function(h, o, t, j, a, r) {
      h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = {hjid: 5150198, hjsv: 6}; // Use your Hotjar Site ID here
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }


  // Render the cookie consent modal if isOpen is true
  return (
    <>
      {isOpen && ( // Only render if isOpen is true
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 9999,
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Vi använder cookies</h5>
              </div>
              <div className="modal-body">
                <p>
                  Vi använder cookies för att förbättra din upplevelse på vår webbplats,
                  analysera vår trafik och anpassa innehåll. Genom att klicka på
                  "Godkänn" samtycker du till vår användning av alla cookies.
                </p>
              </div>
              <div className="modal-footer justify-content-center flex-column w-100 d-flex">
                <button
                  type="button"
                  className="btn btn-success"
                style={{width: "100%"}}
                  onClick={cookieAccept}
                >
                  Godkänn
                </button>
                <button
                  type="button"
                style={{width: "100%", backgroundColor: "transparent", color: "black"}}

                  className="btn btn-secondary"
                  onClick={cookieDeny}
                >
                  Neka
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CookiePrompt;
